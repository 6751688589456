import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/mobile/setting/list',
    method: 'get',
    data: data
  });
}

// WithdrawalStatistics 提现统计
export function WithdrawalStatistics(data) {
    return fetch({
        url: '/api/mobile/withdrawal-statistics',
        method: 'post',
        data: data
    });
}
// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/mobile/user/info',
    method: 'post',
    data: data
  });
}

export function customization(data) {
  return fetch({
    url: '/api/mobile/customization',
    method: 'post',
    data: data
  });
}
// 退出登录
export function logout(data) {
  return fetch({
    url: '/api/mobile/user/logout',
    method: 'delete',
  });
}

// 查询会员账户信息
export function getUserAccountInfo (data) {
  return fetch({
    url: '/api/mobile/userAccount/info',
    method: 'get',
    data
  })
}
// 充值
export function doRecharge (data) {
  return fetch({
    url: '/api/mobile/payRecord/add',
    method: 'post',
    data
  })
}

// ListPayManage
export function ListPayManage(data) {
    return fetch({
        url: '/api/mobile/payManage/list',
        method: 'get',
        data: data
    });
}
// 充值地址信息
export function getRechargeInfo () {
  return fetch({
    url: '/api/mobile/payManage-info',
    method: 'post',
    data: {}
  })
}
// 充值记录
export function getRechargeLog (data) {
  return fetch({
    url: '/api/mobile/recharge-list',
    method: 'post',
    data
  })
}
// 充值统计
export function rechargeStatistics (data) {
  return fetch({
    url: '/api/mobile/recharge-statistics',
    method: 'post',
    data
  })
}
// 公司银行卡信息
export function getCompanyBank (data) {
  return fetch({
    url: '/api/mobile/company-bank',
    method: 'post',
    data
  })
}
// 银行卡充值
export function doBankRecharge (data) {
  return fetch({
    url: '/api/mobile/top-up-submission',
    method: 'post',
    data
  })
}
// 提现
export function doWithdraw (data) {
  return fetch({
    url: '/api/mobile/withdraw/add',
    method: 'post',
    data
  })
}
// 提现记录
export function getWithdrawLog (data) {
  return fetch({
    url: '/api/mobile/withdraw-list',
    method: 'post',
    data
  })
}
// 银行卡列表
export function getCardList (data) {
  return fetch({
    url: '/api/mobile/bank/list',
    method: 'get',
    data
  })
}
// 添加银行卡
export function addCard (data) {
  return fetch({
    url: '/api/mobile/bank/bind',
    method: 'post',
    data
  })
}
// usdt列表
export function getUsdtList (data) {
  return fetch({
    url: '/api/mobile/usdt/list',
    method: 'get',
    data
  })
}
// 添加usdt
export function addUsdt (data) {
  return fetch({
    url: '/api/mobile/usdt/bind',
    method: 'post',
    data
  })
}
// 银行卡列表
export function getBankList (data) {
  return fetch({
    url: '/api/mobile/bank-card-list',
    method: 'post',
    data
  })
}
// 添加银行卡
export function addBank (data) {
  return fetch({
    url: '/api/mobile/bank-card-bind',
    method: 'post',
    data
  })
}
// 修改usdt
export function editUsdt (data, id) {
  return fetch({
    url: `/api/mobile/usdt/update/${id}`,
    method: 'put',
    data
  })
}
// usdt详情
export function usdtDetail (id) {
  return fetch({
    url: `/api/mobile/usdt/info/${id}`,
    method: 'get',
  })
}
// 上传附件
export function uploadFile (data) {
  return fetch({
    url: '/api/mobile/user/uploadImg',
    method: 'put',
    data
  })
}
// 查询资金密码状态
export function checkFundPassword () {
  return fetch({
    url: '/api/mobile/user/transactionPasswordStatus',
    method: 'get',
  })
}
// 设置资金密码
export function setFundPassword (data) {
  return fetch({
    url: '/api/mobile/user/setTransactionPassword',
    method: 'post',
    data
  })
}
// 修改资金密码
export function editFundPassword (data) {
  return fetch({
    url: '/api/mobile/user/changeTransactionPassword',
    method: 'post',
    data
  })
}
// 修改登录密码
export function editPassword (data) {
  return fetch({
    url: '/api/mobile/user/changePassword',
    method: 'post',
    data
  })
}
// 领取奖励统计
export function rewardStatistics (data) {
  return fetch({
    url: '/api/mobile/reward-statistics',
    method: 'post',
    data
  })
}
// 领取奖励列表
export function rewardList (data) {
  return fetch({
    url: '/api/mobile/reward-list',
    method: 'post',
    data
  })
}
// 领取奖励
export function getReward (data) {
  return fetch({
    url: '/api/mobile/receive-reward',
    method: 'post',
    data
  })
}
// 修改用户信息
export function editUser (data) {
  return fetch({
    url: '/api/mobile/user/update',
    method: 'put',
    data
  })
}
// 团队报表
export function teamReport (data) {
  return fetch({
    url: '/api/mobile/team-report',
    method: 'post',
    data
  })
}
// 客服列表
export function getKefuList (data) {
  return fetch({
    url: '/api/mobile/customer-list',
    method: 'post',
    data
  })
}
// 资金类型
export function getDebtType (data) {
  return fetch({
    url: '/api/mobile/user-debt-type',
    method: 'post',
    data
  })
}
// 资金明细
export function getDebtLog (data) {
  return fetch({
    url: '/api/mobile/user-debt-list',
    method: 'post',
    data
  })
}

